// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$atlas-primary: (
        50 : #e7edf9,
        100 : #c2d1f0,
        200 : #99b3e6,
        300 : #7094db,
        400 : #527dd4,
        500 : #3366cc,
        600 : #2e5ec7,
        700 : #2753c0,
        800 : #2049b9,
        900 : #1438ad,
        A100 : #e0e6ff,
        A200 : #adbdff,
        A400 : #7a94ff,
        A700 : #607fff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$atlas-accent: (
        50 : #fff3e0,
        100 : #ffe0b3,
        200 : #ffcc80,
        300 : #ffb84d,
        400 : #ffa826,
        500 : #ff9900,
        600 : #ff9100,
        700 : #ff8600,
        800 : #ff7c00,
        900 : #ff6b00,
        A100 : #ffffff,
        A200 : #fff7f2,
        A400 : #ffd6bf,
        A700 : #ffc6a6,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$atlas-primary: mat.define-palette(mat.$light-blue-palette, 800);
$atlas-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$atlas-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$atlas-theme: mat.define-light-theme((
        color: (
                primary: $atlas-primary,
                accent: $atlas-accent,
                warn: $atlas-warn,
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($atlas-theme);

/* You can add global styles to this file, and also import other style files */

/*
 ** Customizations
 */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.small-list-icon {
  width: 32px;
  height: 32px;
  font-size:32px;
}

.small-icon {
  width: 64px;
  height: 64px;
  padding-right: 10px;
}

.nav-spacer {
  flex: 1 1 auto;
}

/*
One of the CSS from @aws-amplify (namely node_modules/@aws-amplify/ui/src/Angular.css) defines a bunch of styles for generic HTML elements like:
span {
  color: var(--color-gray);
}

This screws up a number of things across the app... So unsetting those styles here. Consider removing when @aws-amplify is updated
*/
span {
  color: unset;
}

p,h1,h2,h4,h5 {
  height: unset;
  font-weight: unset;
}

.myCustomDialogPanel {
  .mat-dialog-container {
    border-radius: 50px;
    border: 1px solid orange;
    background-color: yellow !important;
  }
}

//.mat-mdc-dialog-container {
//  --mdc-dialog-container-shape: 50px;
//}

.cdk-overlay-pane mat-dialog-container.mat-dialog-container { // <- in this way all other styles
  margin: 20px 5px;
  padding: 30px;
  border-radius: 20px !important;
}
.mat-dialog-container {
  border-radius: 20px !important;
}
